<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>商品管理</template>
      <template v-slot:secondMenu>完整分类</template>
    </breadcrumb-nav>
    <el-card style="margin-top: 20px;">
      <tree-table
        :data="list"
        :columns="columns"
        :selection-type="false"
        :expand-type="false"
        show-index
        index-text="#"
        border
        class="tree-table">
        <template slot="order" slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.level === 1">一级</el-tag>
          <el-tag type="success" size="mini" v-else-if="scope.row.level === 2">二级</el-tag>
          <el-tag type="warning" size="mini" v-else>三级</el-tag>
        </template>
        <template slot="status" slot-scope="scope">
          <i class="el-icon-success checkIcon" v-if="scope.row.status===1"></i>
          <i class="el-icon-error closeIcon" v-else></i>
        </template>
      </tree-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.page"
        :page-sizes="[5,10,20]"
        :page-size="listQuery.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { getWholeCategoriesListRequest } from '../../../network/goods'

export default {
  name: 'WholeCategory',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      listQuery: {
        page: 1,
        size: 10
      },
      columns: [
        {
          label: '分类名称',
          prop: 'name'
        },
        {
          label: '级数',
          type: 'template',
          prop: 'level',
          template: 'order'
        }
      ]
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.listCategory()
  },
  methods: {
    listCategory () {
      getWholeCategoriesListRequest(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取品牌列表失败', 'error')
        }
        this.list = result.data.list
        this.total = result.data.total
      })
    },
    // 监听size改变
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.listCategory()
    },
    // 监听page的改变
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.listCategory()
    }
  }
}
</script>

<style scoped>

</style>
